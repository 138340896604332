.failure-container {
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff;
    z-index: 10;
}
.failure-animation{
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
    
}

.failure-animation .circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffcccc;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pop 0.75s ease-out;
}

.failure-animation .cross {
  font-size: 64px;
  color: #ff0000;
}

.failure-message {
  margin-top: 20px;
  font-size: 24px;
  color: #ff0000;
}

.failure-reason {
  font-size: 16px;
  color: #888;
  margin-top: 10px;
}

.retry-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


@keyframes pop {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }