.course-card {
    border: 2px solid;
    border-radius: 8px;
    width: 300px;
    margin: 10px auto;
    font-family: Arial, sans-serif;
    direction: rtl;
  }
  
  .course-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    font-weight: bold;
  }
  
  .status-icon {
    font-size: 24px;
  }
  
  .course-body {
    padding: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .course-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .course-date {
    font-size: 16px;
    color: #555;
  }

  .course-card {
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .course-card.orange {
    background-color: #ffcc66;
  }
  
  .course-card.red {
    background-color: #ff6666;
  }
  
  .course-card.green {
    background-color: #66cc66;
  }
  
  .course-header {
    display: flex;
    align-items: center;
  }
  
  .expand-btn {
    background-color: transparent;
    border: none;
    font-size: 18px;
    margin-left: 10px;
  }
  
  .course-name {
    font-weight: bold;
    flex-grow: 1;
  }
  
  .course-details {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  
  .course-status-label {
    font-weight: bold;
  }
  
  .course-status, .course-date {
    margin-left: 5px;
  }
  