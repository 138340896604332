.success-container {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff;
  }
  
  .checkmark-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #b0f1b0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pop 0.5s ease-out;
  }
  
  .checkmark {
    font-size: 48px;
    color: #fff;
    animation: fadeIn 1s ease-in;
  }
  
  .success-text {
    margin-top: 20px;
    font-size: 18px;
    color: #28a745;
    animation: fadeIn 2s ease-in;
  }
  
  @keyframes pop {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  