.user-profile {
    display: flex;
    align-items: center;
    /* padding: 4px; */
    padding-top: 12px;
    /* padding: 16px; */
    /* background-color: #f9f9f9;  */
    /* צבע רקע קל */
    /* border-radius: 12px; */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* עיגול */
    object-fit: cover;
    margin-left: 12px;

    border: 4px solid white; /* מסגרת לתמונה */
    /* border: 10px solid #ddd; מסגרת לתמונה */
  }
  
  .user-name {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #230890;
    margin-left: 1em;
    direction: rtl; /* טקסט ימין לשמאל */
  }
  