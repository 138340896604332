/* רקע כהה */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* תיבת תוכן */
  .popup-content {
    width: 90%;
    max-width: 350px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    direction: rtl;
    text-align: center;
    position: relative;
  }
  
  /* כפתור חזרה */
  .back-btn {
    position: absolute;
    top: 15px;
    left: 20px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
  }
  
  /* מידע על הקורס */
  .course-info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
    background: #FFFFFF;
box-shadow: 0px 1px 4px rgba(153, 128, 172, 0.5);
border-radius: 15px;
padding: 10px 10px;
gap: 7px;
  }
  
  .course-image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  
  /* אפשרויות תשלום */
  .payment-options label {
    margin: 0 10px;
    font-size: 14px;
    cursor: pointer;
  }
  
  /* תנאים */
  .terms {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;
  }
  
  .terms input {
    margin-left: 5px;
  }
  
  /* כפתור תשלום */
  .pay-btn {
    background-color: #00c853;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .pay-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  