.stepper-container {
  text-align: center;
  direction: rtl;
}

.progress-text {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

/* מבנה הסרגל */
.stepper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* עיגול הסטטוס */
.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid;
  /* border: 2px solid #ccc; */
  background-color: #ccc;
  z-index: 1;
}

/* קווים מחברים בין העיגולים */
.connector {
  width: 50px;
  height: 4px;
  background-color: #ccc;
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 0;
}

/* עיצוב הסטטוסים */
.PURPLE {
    
    border: 6px solid #9b59b6;
  background-color: white;
}

.GREEN {
    border: 6px solid;
  border-color: #27ae60;
  /* background-color: #abebc6; */
}

.RED {
    border: 6px solid;
  border-color: #e74c3c;
  /* background-color: #f5b7b1; */
}

/* .pending {
  border-color: #ccc;
  background-color: #f0f0f0;
} */

.step-label {
  margin-top: 8px;
  font-size: 14px;
  color: #888;
}

.step-label.current {
  color: #9b59b6;
  font-weight: bold;
}

.step-label.completed {
  color: #27ae60;
}

.step-label.missed {
  color: #e74c3c;
}