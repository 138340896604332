/* רקע כהה חצי שקוף */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* עיצוב התיבה */
  .popup-content {
    width: 90%;
    min-height:50% ;
    max-width: 350px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    position: relative;
  }
  
  /* כפתור סגירה */
  .close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #ccc;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* תמונה */
  .popup-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  /* כותרת */
  .popup-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* תיאור */
  .popup-description,
  .popup-details {
    font-size: 14px;
    text-align: right;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  /* כפתור הרשמה */
  .register-btn {
    background-color: #00c853;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .register-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  /* .register-btn:hover {
    background-color: #00e676;
  } */
  