.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    position: relative;
    background: white;
    width: 340px;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-btn {
    position: absolute;
    top: -20px;
    right: calc(50% - 20px);
    background: #fff;
    color: black;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  
  .signature-canvas {
    border: 2px solid #ddd;
    border-radius: 24px;
    width: 100%;
    margin-top: 20px;
    background:#9BBAE3;
    touch-action: none;
  }
  
  .button-group {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
  
  .clear-btn,
  .save-btn {
    width: 40%;
    padding: 10px 20px;
    border: 2px solid black;
    border-radius: 16px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .clear-btn {
    background-color: #FF4D00;
    color: black;
  }
  
  .save-btn {
    background-color: #00FD0A;
    color: black;
  }
  
  .clear-btn:hover {
    background-color: #FF4D00;
    opacity: 90%;
  }
  
  .save-btn:hover {
    background-color: #45a047;
  }
  .send-button {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: #00FD0A;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }
  .send-button-dis {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    
    background: #00FD0A;
    background: rgba(197, 194, 194, 0.11);
border: 1px solid rgba(217, 217, 217, 0.74);
    border-radius: 16px;
  }
  
  /* תיבת האייקון */
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid #d1c4e9;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  /* עיצוב האייקון */
  .send-icon {
    font-size: 20px;
    color: #b39ddb;
    transition: transform 0.3s ease;
  }
  
  /* עיצוב הטקסט */
  .send-text {
    /* font-family: 'Actor'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #000000;
    
  
  }
  .send-text-dis {
    /* font-family: 'Actor'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #C5C2C2;
    
  
  }
 




