.time-input-container {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
  }
  
  .time-input-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }
  
  .arrow-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .arrow-button:hover {
    background-color: #007bff;
    color: white;
    border-radius: 2px;
  }
  
  .time-input-field {
    width: 30px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 2px;
    font-size: 14px;
  }
  
  .time-input-field:focus {
    outline: none;
    border-color: #007bff;
  }