/* סגנון עבור כל כותרות העמודות */
.rdt_TableHeadRow div,
.rdt_TableHeadRow span {
  white-space: normal !important; /* שבירת שורות */
  overflow-wrap: break-word !important; /* שבירת מילים ארוכות */
  text-align: center !important; /* טקסט ממורכז */
  font-size: 12px; /* גודל פונט */
  font-weight: bold; /* טקסט מודגש */
  /* padding: 8px;  */
  /* ריווח פנימי */
  min-width: 80px; /* רוחב מינימלי */
}

/* סגנון עבור תאים בטבלה */
.rdt_TableCell {
  white-space: normal !important;
  overflow-wrap: break-word !important;
  text-align: center;
  padding: 8px;
  min-width: 80px;
}
